$( document ).on('turbolinks:load', function() {
  // nav burger
  $('.nav-burger').click(function() {
    $('.nav-links-container').slideToggle();
  });


  // link hover
  $("a").mouseover(function() {
    var colors = ['#f47373c0', '#ef86dfc0', '#c58adec0', '#907dbac0', '#6d8de1c0', '#6cc2dbc0', '#81d89bc0', '#eeda60c0', '#eeb760c0'];
    var random_color = colors[Math.floor(Math.random() * colors.length)];
    $(this).css("color", random_color);
    $(this).find("i").css("color", random_color);
  }).mouseout(function() {
      $(this).css("color","#333333");
      $(this).find("i").css("color","#333333");
  });

  // jump to arrow
  $("[data-js='scroll-down").click(() => {$('html, body').animate({scrollTop: $("[data-js='scroll-to").offset().top - 30}, '300')})

  // back to the top
  var btn = $('#button');

  $(window).scroll(function() {
    if ($(window).scrollTop() > 300) {
      btn.addClass('show');
    } else {
      btn.removeClass('show');
    }
  });

  btn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
  });

  // about page
  $( ".about-image-container" ).find(".overlay").css('background-color', "#81d89bc0");

  $( ".about-image-container" ).click(function() {
    $( ".about-image-container" ).find(".overlay").removeClass('d-flex')
    $( ".about-image-container" ).find(".overlay").addClass('d-none')
    $( ".about-text-container" ).removeClass('d-flex')
    $( ".about-text-container" ).addClass('d-none')
    $(this).find(".overlay").removeClass('d-none')
    var colors_trans = ['#f47373c0', '#ef86dfc0', '#c58adec0', '#907dbac0', '#6d8de1c0', '#6cc2dbc0', '#81d89bc0', '#eeda60c0', '#eeb760c0'];
    var random_trans_color = colors_trans[Math.floor(Math.random() * colors_trans.length)];
    $(this).find(".overlay").css('background-color', random_trans_color);
    $(this).find(".overlay").addClass('d-flex')
    var dataImageTag = $(this).data('image')
    $(".about-text-container[data-show='" + dataImageTag +"']").removeClass('d-none')
    $(".about-text-container[data-show='" + dataImageTag +"']").addClass('d-flex')
  });

});
